.Custom_Spinner_Wrap {
  position: block;
  width: 20px;
  height: 20px;
  margin: 50px auto;
}

.Final {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999999;
  text-align: center;
}
