@import '../../assets/styles/variables.scss';

.HeaderWrapper {
  // margin-top: 65px;
  padding-left: 50px;
}

.Build {
  margin: 15px 0 0 0;
}

.HeaderDesc {
  margin-top: 15px;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
}

.Sign_In_Form_Wrap {
  min-width: 360px;
  max-width: 400px;
  padding-right: 50px;

  .Remember_Forgot_Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 34px;
  
    a {
      font-weight: 500;
      font-size: 0.8em;
      line-height: 1.5;
      text-decoration-line: underline;
      color: $neutral-color;
  
      &:hover {
        color: $neutral-color;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .HeaderWrapper {
    margin-top: 15px;
    padding-left: 0;
    text-align: center;
  }
  
  .Build {
    margin: 15px 0 0 0;
  }
  
  .HeaderDesc {
    margin-top: 15px;
    font-size: 30px;
    font-weight: 500;
  }

  .Sign_In_Form_Wrap {
    width: 100%;
    margin-top: 36px;
    max-width: 100%;
    min-width: auto;
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .Sign_In_Form_Wrap {
    .Sign_In_Form_Header {
      flex-direction: column;
  
      a {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}
