@import "../../assets/styles/variables.scss";

.Footer {
  background-color: $secondary-color;
  padding: 30px 0;
  margin-top: auto; 

  .Sub_Title {
    font-size: 1.125em;
    line-height: 27px;
    color: $white-color;
    margin-bottom: 24px;
  }

  .Title {
    font-weight: 600;
    font-size: 2em;
    line-height: 48px;
    color: $white-color;
    margin-bottom: 24px;
  }

  .Search_Types {
    li {
      margin-bottom: 28px;

      a {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: $white-color;
        padding: 5px;
  
        &:hover {
          text-decoration: none;
        }

        &:focus {
          outline: 1px solid white;
          border-radius: 5px;
        }
      }
    }
  }

  .Footer_Links_List {
    width: fit-content;
    margin-left: auto;

    li {
      margin-bottom: 28px;

      a {
        font-weight: 500;
        font-size: 1em;
        line-height: 24px;
        color: $white-color;
        text-decoration: underline;
        padding: 5px;
  
        &:hover {
          text-decoration: none;
        }

        &:focus {
          outline: 1px solid white;
          border-radius: 5px;
        }
      }
    }
  }

  .Bottom_Footer_Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 1em;
      line-height: 24px;
      color: $white-color;
      margin: 0 auto;

      a {
        text-decoration: underline;
        color: $white-color;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .Social_List {
      display: flex;
      align-items: center;

      .Social_List_Item {
        width: 23px;
        height: 23px;
        margin-left: 22px;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          svg {
            width: 18px;
            height: 18px;
          }

          svg path {
            fill: $white-color;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .Footer {
    .Footer_Links_List {
      margin-left: 0;
    }
  }
}

@media (max-width: 768px) {
  .Footer {
    text-align: center;

    .Footer_Links_List {
      width: auto;
    }

    .Search_Types {
      margin-top: 30px;
    }
  }
}

@media (max-width: 578px) {
  .Bottom_Footer_Inner {
    flex-direction: column;

    span {
      margin-bottom: 20px;
    }
  }
}