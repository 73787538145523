@import '../../assets/styles/variables.scss';

.Border_Bottom {
  border-bottom: 1px solid #ccc;
}

.Table {
  border: 1px solid #CFDCE5;
  border-radius: 8px;

  .Table_Head {
    tr {
      background-color: $white-color;

      th {
        font-weight: 600;
        font-size: 0.8em;
        line-height: 21px;
        color: $secondary-color;
        border: none;
      }
    }

    svg {
      margin-left: 7px;
    }
  }

  .Table_Body {
    .Entity {
      border-radius: 35px;
      padding: 2px 5px;
      color: $white-color;
      background-color: $accent-color;
      display: inline-block;
    }

    tr:nth-of-type(even) {
      background-color: #F5F8FA;
    }

    tr:nth-of-type(odd) {
      background-color: $white-color;
    }

    .Title_Normal {
      font-size: 0.875em;
      line-height: 21px;
      color: $secondary-color;
      font-weight: 500;
    }

    .Title_Bold {
      font-weight: 600;
      font-size: 0.875em;
      line-height: 21px;
      color: $secondary-color;
      margin-bottom: 5px;
    }

    .Title_Light {
      font-size: 0.75em;
      line-height: 20px;
      letter-spacing: -0.2px;
      color: $medium-color;
      margin-bottom: 0;
    }
  }

  .No_Wrap {
    white-space: nowrap;
  }

  .Vertical_Middle {
    vertical-align: middle;
  }
}

.Support_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white-color;
  border: 1px solid #CFDCE5;
  padding: 17px 23px;
  margin-bottom: 20px;

  span {
    padding: 1px 6px;
    background: #337AB7;
    border-radius: 50px;
    color: $white-color;
  }
}

.Custom_Min_Height {
  min-height: 38px;
}

.Delete_Icon {
  cursor: pointer;
}

@media (max-width: 768px) {
  .Table {
    td {
      a {
        margin: 10px 0!important;
      }
    }
  }
}