@import '../../assets/styles/variables.scss';

.User_Registration {
  padding: 88px 0 144px;
}

.HeaderWrapper {
  margin-top: 65px;
  padding-left: 50px;
}

.Build {
  margin: 15px 0 0 0;
}

.HeaderDesc {
  margin-top: 15px;
  font-size: 30px;
  font-weight: 500;
}

.Sign_Up_Form_Wrap {
  min-width: 360px;
  max-width: 400px;
  padding-right: 50px;
}