@import '../../assets/styles/variables.scss';

.Step_Wrapper {
  background-color: #fff;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.Step_Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #e2e2e2;
}

.Step_Content {
  padding: 10px 0;
  height: 650px;
  overflow: auto;

  form {
    padding: 20px 50px;
  }
}

.Step_Footer {
  padding: 10px 0;
  border-top: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Arrow_Icon {
  width: 15px;
  height: 15px;
}

.Arrow_Wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: $accent-color;
  cursor: pointer;
}

.Finish_Wrapper {
  background: $accent-color;
  cursor: pointer;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
}

.Dots_Container {
  display: flex;
  align-items: center;
  justify-content: center;

  .Active_Dot, .Inactive_Dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
  }

  .Active_Dot {
    background: $accent-color;
  }

  .Inactive_Dot {
    background-color: #F4F8F9;
    border: 1px solid #ccc;
  }
}

.Prev, .Next {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
}

.Prev {
  width: 32px;
}

.Registration_Check_List {
  margin-bottom: 52px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    margin: 23px 0;

    svg {
      width: 15px;
    }

    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 1.125em;
      line-height: 27px;
      color: $secondary-color;
    }
  }
}

.Frame_Three {
  p {
    span {
      color: #FF2828;
    }
  }
}
