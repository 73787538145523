@import '../../assets/styles/variables.scss';

.Heading_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Search_Wrapper {
  input {
    margin-bottom: 0;
    padding: 16px 24px 16px 45px!important;
  }

  label {
    display: none;
  }

  svg {
    position: absolute;
    top: 18px;
    left: 27px;
  }

  svg path {
    fill: $neutral-color;
  }
}

.Total_Awards_Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  .Statistics_Wrapper {
    display: flex;
    align-items: center;
  }

  form {
    width: 200px;
  }
}

.Filter_Wrapper {
  display: flex;
  align-items: center;

  input {
    margin: 0!important;
  }
}

.Contact_Title {
  margin-bottom: 0;
  line-height: 110%;
  font-size: 10px;
}

.Contact_Entity {
  margin-top: 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.Awards_Title {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}

.Awards_Entity {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}

.No_Data {
  color: #810000;
}

.XLSX_Wrapper {
  text-align: right;
}

.ColumnDropdown {
  height: 56px !important;;
  border-radius: 0 !important;
  width: 100% !important;
  background: $white-color !important;
  border: 1px solid $neutral-color !important;
  color: $secondary-color !important;
  text-align: left !important;
  font-size: 15px!important;

  &:focus {
    box-shadow: none!important;
    border: 1px solid $secondary-color !important;
  }
}

.Awards_TR_Focus {
  &:hover {
    background-color: #f1f1f1!important;
    cursor: pointer;
  }
}

.Awards_Details_Wrapper {
  border-bottom: 1px solid $neutral-color;
  padding: 5px 5px 10px;
  margin-bottom: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .XLSX_Wrapper {
    text-align: center;
  }

  .Total_Awards_Wrapper {
    flex-direction: column;

    h5, h6, form {
      margin: 20px 0;
    }
  }
}

.Wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  .Item1,
  .Item2,
  {
    border: 1px solid #CFDCE5;
    border-radius: 8px;
    position:relative;
    background: #fff;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: box-shadow .3s;

    

    &:hover {
      box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }

    &:hover .Tooltip {
      opacity:1;
      visibility:visible;
      z-index:10;
    }
  }

  .Item1 { grid-area: 1 / 1 / 2 / 2; }
  .Item2 { grid-area: 1 / 2 / 2 / 3; }

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);

    .Item1 { grid-area: 1 / 1 / 2 / 2; }
    .Item2 { grid-area: 1 / 2 / 2 / 3; }
  }
}

.Title {
  font-size: 0.875em;
  line-height: 21px;
  color: #233539;
  font-weight: 500;
  margin: 0;
}

.Value {
  font-weight: 600;
  font-size: 1em;
  line-height: 21px;
  color: #233539;
  margin-bottom: 15px;
}

.Tooltip {
  opacity:0;
  visibility:hidden;
  transform: translateX(-50%);
  position: absolute;
  color: #fff;
  background-color: #233539;
  border-radius: 4px;
  padding: 15px 10px;
  z-index: -1;
  width: 115%;
  bottom: 100%;
  left: 50%;
  text-align: center;
  transition:opacity 0.2s ease-in-out;
  font-size: 12px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-color: #233539 rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 15px 15px 0;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
  }  
}