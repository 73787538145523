.Wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  .Item1,
  .Item2,
  .Item3,
  .Item4,
  .Item5,
  .Item6,
  .Item7,
  .Item8,
  .Item9,
  .Item10,
  .Item11,
  .Item12,
  .Item13,
  .Item14,
  .Item15,
  .Item16,
  .Item17 {
    border: 1px solid #CFDCE5;
    border-radius: 8px;
    position:relative;
    background: #fff;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: box-shadow .3s;

    

    &:hover {
      box-shadow: 0 0 11px rgba(33,33,33,.2); 
    }

    &:hover .Tooltip {
      opacity:1;
      visibility:visible;
      z-index:10;
    }
  }

  .Item1 { grid-area: 1 / 1 / 2 / 2; }
  .Item2 { grid-area: 1 / 2 / 2 / 3; }
  .Item3 { grid-area: 1 / 3 / 2 / 4; }
  .Item4 { grid-area: 2 / 1 / 3 / 2; }
  .Item5 { grid-area: 2 / 2 / 3 / 3; }
  .Item6 { grid-area: 2 / 3 / 3 / 4; }
  .Item7 { grid-area: 3 / 1 / 4 / 2; }
  .Item8 { grid-area: 3 / 2 / 4 / 3; }
  .Item9 { grid-area: 3 / 3 / 4 / 4; }
  .Item10 { grid-area: 4 / 1 / 5 / 2; }
  .Item11 { grid-area: 4 / 2 / 5 / 3; }
  .Item12 { grid-area: 4 / 3 / 5 / 4; }
  .Item13 { grid-area: 5 / 1 / 6 / 2; }
  .Item14 { grid-area: 5 / 2 / 6 / 3; }
  .Item15 { grid-area: 5 / 3 / 6 / 4; }

  @media only screen and (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);

    .Item1 { grid-area: 1 / 1 / 2 / 2; }
    .Item2 { grid-area: 1 / 2 / 2 / 3; }
    .Item3 { grid-area: 2 / 1 / 3 / 2; }
    .Item4 { grid-area: 2 / 2 / 3 / 3; }
    .Item5 { grid-area: 3 / 1 / 4 / 2; }
    .Item6 { grid-area: 3 / 2 / 4 / 3; }
    .Item7 { grid-area: 4 / 1 / 5 / 2; }
    .Item8 { grid-area: 4 / 2 / 5 / 3; }
    .Item9 { grid-area: 5 / 1 / 6 / 2; }
    .Item10 { grid-area: 5 / 2 / 6 / 3; }
    .Item11 { grid-area: 6 / 1 / 7 / 2; }
    .Item12 { grid-area: 6 / 2 / 7 / 3; }
    .Item13 { grid-area: 7 / 1 / 8 / 2; }
    .Item14 { grid-area: 7 / 2 / 8 / 3; }
    .Item15 { grid-area: 8 / 1 / 9 / 2; }
    .Item16 { grid-area: 8 / 2 / 9 / 3; }
    .Item17 { grid-area: 9 / 1 / 10 / 2; }
  }
}

.Title {
  font-size: 0.875em;
  line-height: 21px;
  color: #233539;
  font-weight: 500;
  margin: 0;
}

.Value {
  font-weight: 600;
  font-size: 1em;
  line-height: 21px;
  color: #233539;
  margin-bottom: 15px;
}

.Tooltip {
  opacity:0;
  visibility:hidden;
  transform: translateX(-50%);
  position: absolute;
  color: #fff;
  background-color: #233539;
  border-radius: 4px;
  padding: 15px 10px;
  z-index: -1;
  width: 115%;
  bottom: 100%;
  left: 50%;
  text-align: center;
  transition:opacity 0.2s ease-in-out;
  font-size: 12px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    border-color: #233539 rgba(0, 0, 0, 0);
    border-style: solid;
    border-width: 15px 15px 0;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
  }  
}

.Heading_Wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}