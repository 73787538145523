.Youtube_Container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 20px;
  overflow: hidden;
  .Youtube_Video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
