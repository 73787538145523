@import '../../assets/styles/variables.scss';

.Header {
  background-color: $white-color;
  padding: 15px 0;

  .Header_Col {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .Header_Logo_Search_Wrap {
      display: flex;
      align-items: center;    
    }
  }

  .Header_Link {
    font-weight: 500;
    font-size: 1em;
    line-height: 1.5;
    color: $white-color;

    &:first-child {
      margin-right: 65px;
    }
  }

  .Headers_Inbox_Wrap {
    display: flex;
    align-items: center;

    .Inbox_Link {
      margin-right: 50px;
      position: relative;
      display: flex;
      color: #233539;
      align-items: center;
      gap: 5px;

      .Inbox_Unread_Number {
        position: absolute;
        top: -6px;
        left: 15px;
        background: $accent-color;
        color: $white-color;
        border-radius: 35px;
        min-width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 9px !important;
        padding: 0 3px;
      }
    }
  }
}

.Main_Nav {
  display: flex;
  align-items: center;
  width: 100%;
}

@media (max-width: 1230px) {
  Header {
    .Header_Col {
      flex-direction: column;
    }

    .Main_Nav {
      justify-content: center;
      margin: 20px 0;
    }
  }
}

@media (max-width: 992px) {
  .Header {
    .Header_Col {
      justify-content: center;
    }

    .Headers_Inbox_Wrap {
      .Inbox_Link {
        margin-right: 0px;
      }
  }
}
}

@media (max-width: 768px) {
  .Header {
    .Header_Col {
      flex-wrap: wrap;
      justify-content: center;

      .Header_Logo_Search_Wrap {
        margin-bottom: 20px;
        flex-direction: column;

        & > a,
        & > div {
          margin: 10px 0;
        }
      }
    }

    .Headers_Inbox_Wrap {
      .Inbox_Link {
        margin-right: 0px;
        margin-left: 10px;
      }
    }
  }

  .Main_Nav_Mobile_Button {
    font-size:22px !important;
    cursor:pointer;
    position: relative;
    top: 4px;
    transform: scaleX(1.3);
  }
}


@media (max-width: 575px) {
  .Main_Nav {
    display: flex;
    position: relative;
    flex-direction: column;
  }
}